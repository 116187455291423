import React, { Suspense, useContext } from "react";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { BottomNavContext } from "../../BottomNavContext";

/* components */
import PageTitle from "../../components/common/PageTitle";
import EditPost from "../EditPost";
import PostList from "../PostList";
import PostDetail from "../../components/PostDetail";
import { HeadPostButton } from "../../components/HeadButton";

/* styles */
import "./styles.scss";

function Posts({ postType = "projects" }) {
  const { showBottomNav } = useContext(BottomNavContext);

  let match = useRouteMatch();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isPostScreen, setIsPostScreen] = React.useState(false);

  let postLabel = "Project";
  let description = "";

  switch (postType) {
    case "projects":
      postLabel = "Project";
      description = "活動紹介やアイデア・企画案など";
      break;
    case "recommends":
      postLabel = "Recommend";
      description = "おすすめ情報をシェア";
      break;
    case "wanted":
      postLabel = "Wanted";
      description = "仲間募集や仕事募集、クリエイター紹介など";
      break;
    case "information":
      postLabel = "Information";
      description = "CEKAI運営チームからのお知らせ";
      break;
    default:
      break;
  }

  React.useEffect(() => {
    const path = location.pathname;
    switch (true) {
      case /\/post/.test(path):
        setIsPostScreen(true);
        break;
      case /\/edit/.test(path):
        setIsPostScreen(true);
        break;
      default:
        setIsPostScreen(false);
        break;
    }
  }, [location]);

  return (
    <div
      className="contents"
      style={{ paddingBottom: isMobile && showBottomNav ? "80px" : "0" }}
    >
      <PageTitle
        title={postLabel}
        subTitle={description}
        rightButton={
          isMobile || isPostScreen || <HeadPostButton postType={postType} />
        }
      />
      <Switch>
        <Route path={`${match.path}/:pid/edit`}>
          <EditPost postType={postType} />
        </Route>
        <Route path={`${match.path}/:pid`}>
          <Suspense fallback={<p>Loading......</p>}>
            <PostDetail postType={postType} />
            <PostList postType={postType} />
          </Suspense>
        </Route>
        <Route path={match.path}>
          <PostList postType={postType} />
        </Route>
      </Switch>
    </div>
  );
}

export default Posts;
