import React from "react";

export const Add = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="11.7646" y1="5.5" x2="-5.82178e-05" y2="5.5" stroke="white" />
      <line x1="5.5" y1="11.8824" x2="5.5" y2="0.11774" stroke="white" />
    </svg>
  );
};

export const Delete = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.53696 0C5.86128 0 6.1242 0.26292 6.1242 0.587249V1.94631C6.1242 2.27064 5.86128 2.53356 5.53696 2.53356C5.21263 2.53356 4.94971 2.27064 4.94971 1.94631V0.587249C4.94971 0.26292 5.21263 0 5.53696 0Z"
        fill="#BF4141"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.09732C0 1.77299 0.26292 1.51007 0.587249 1.51007H10.5201C10.8445 1.51007 11.1074 1.77299 11.1074 2.09732C11.1074 2.42165 10.8445 2.68457 10.5201 2.68457H0.587249C0.26292 2.68457 0 2.42165 0 2.09732Z"
        fill="#BF4141"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.86732 3.54922C10.1867 3.60566 10.3999 3.91033 10.3434 4.22971L8.88185 12.5H2.13357L0.812776 4.25394C0.761481 3.9337 0.97951 3.6325 1.29976 3.58121C1.62 3.52991 1.9212 3.74794 1.97249 4.06819L3.13492 11.3255H7.89672L9.18684 4.02531C9.24328 3.70593 9.54794 3.49278 9.86732 3.54922Z"
        fill="#BF4141"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.09389 4.71692C4.41702 4.68909 4.70154 4.92847 4.72937 5.2516L5.11528 9.73147C5.14311 10.0546 4.90373 10.3391 4.5806 10.367C4.25746 10.3948 3.97295 10.1554 3.94511 9.83228L3.55921 5.35241C3.53137 5.02927 3.77076 4.74476 4.09389 4.71692Z"
        fill="#BF4141"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89603 4.71692C7.21916 4.74476 7.45854 5.02927 7.43071 5.35241L7.0448 9.83228C7.01697 10.1554 6.73245 10.3948 6.40932 10.367C6.08619 10.3391 5.8468 10.0546 5.87464 9.73147L6.26054 5.2516C6.28838 4.92847 6.57289 4.68909 6.89603 4.71692Z"
        fill="#BF4141"
      />
    </svg>
  );
};

export const Photo = () => {
  return (
    <svg
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1326 0H33.7611L37.6725 6.27062H42.5512C46.1726 6.27062 49.1322 9.23023 49.1322 12.8517V39.3621H0.705688V12.9137C0.705688 9.29231 3.6653 6.3327 7.28673 6.3327H12.2213L16.1326 0ZM18.5565 4.34597L14.6451 10.6787H7.28673C6.06551 10.6787 5.05166 11.6925 5.05166 12.9137V35.0161H44.7863V12.8517C44.7863 11.6304 43.7724 10.6166 42.5512 10.6166H35.2612L31.3498 4.34597H18.5565Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.919 17.1356C22.073 17.1356 19.7659 19.4427 19.7659 22.2886C19.7659 25.1346 22.073 27.4417 24.919 27.4417C27.7649 27.4417 30.0721 25.1346 30.0721 22.2886C30.0721 19.4427 27.7649 17.1356 24.919 17.1356ZM15.4199 22.2886C15.4199 17.0425 19.6728 12.7896 24.919 12.7896C30.1652 12.7896 34.418 17.0425 34.418 22.2886C34.418 27.5348 30.1652 31.7877 24.919 31.7877C19.6728 31.7877 15.4199 27.5348 15.4199 22.2886Z"
        fill="white"
      />
    </svg>
  );
};

export const ICalendar = () => {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.14739 6.10334C2.83144 6.10334 2.60078 6.33401 2.60078 6.64995V22.9025C2.60078 23.2184 2.83145 23.4491 3.14739 23.4491H24.9025C25.2156 23.4491 25.4491 23.1853 25.4491 22.9389V6.64995C25.4491 6.33401 25.2184 6.10334 24.9025 6.10334H3.14739ZM0.0499268 6.64995C0.0499268 4.92521 1.42265 3.55249 3.14739 3.55249H24.9025C26.6272 3.55249 27.9999 4.92521 27.9999 6.64995V22.9389C27.9999 24.6604 26.5572 26 24.9025 26H3.14739C1.42265 26 0.0499268 24.6272 0.0499268 22.9025V6.64995Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.7753 0.199951C8.4797 0.199951 9.05073 0.770978 9.05073 1.47538V7.59741C9.05073 8.30181 8.4797 8.87284 7.7753 8.87284C7.07091 8.87284 6.49988 8.30181 6.49988 7.59741V1.47538C6.49988 0.770978 7.07091 0.199951 7.7753 0.199951Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7482 0.199951C21.4526 0.199951 22.0236 0.770978 22.0236 1.47538V7.59741C22.0236 8.30181 21.4526 8.87284 20.7482 8.87284C20.0438 8.87284 19.4728 8.30181 19.4728 7.59741V1.47538C19.4728 0.770978 20.0438 0.199951 20.7482 0.199951Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.609 11.9684C21.0528 12.5154 20.9691 13.3186 20.4221 13.7624L11.8658 20.7044L7.48626 16.9058C6.95414 16.4443 6.89692 15.6388 7.35845 15.1066C7.81999 14.5745 8.62552 14.5173 9.15764 14.9788L11.9205 17.3752L18.815 11.7815C19.362 11.3377 20.1652 11.4214 20.609 11.9684Z"
        fill="white"
      />
    </svg>
  );
};

export const IPost = () => {
  return (
    <svg
      width="21"
      height="30"
      viewBox="0 0 21 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.315 2.9216C12.3147 2.92202 12.3144 2.92244 12.3141 2.92287L2.56079 16.9767L2.81565 21.8299L7.29539 20.9463L17.3741 7.11056C17.5643 6.82914 17.5045 6.41317 17.2374 6.2043L17.2213 6.19174L13.1303 2.77485C12.8709 2.58193 12.5153 2.63614 12.315 2.9216ZM10.2919 1.34158C11.31 -0.115705 13.245 -0.443855 14.6368 0.644481L14.6529 0.657045L18.7501 4.07914C20.1175 5.1645 20.4112 7.23926 19.391 8.6996L19.3835 8.71035L8.70071 23.3753L0.463611 25L0 16.1715L10.2919 1.34158Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 28.5C1 27.6716 1.56982 27 2.27273 27H19.7273C20.4302 27 21 27.6716 21 28.5C21 29.3284 20.4302 30 19.7273 30H2.27273C1.56982 30 1 29.3284 1 28.5Z"
        fill="white"
      />
    </svg>
  );
};

export const IEdit = ({ color = "white", size = "m" }) => {
  const _color = color === "white" ? "#fff" : "#000";
  const _size = size === "m" ? "14" : size === "l" ? "20" : "10";
  return (
    <svg
      width={_size}
      height={_size}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2412 0.300963L19.3502 1.16545C20.0586 1.67222 20.2127 2.65595 19.689 3.34158L12.5425 12.1653L9 13V9.42281L15.9925 0.628872C16.5162 -0.0567564 17.5327 -0.205806 18.2412 0.300963Z"
        fill={_color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H11.3852V2.17514H2.13663V17.4322C2.13663 18.7581 3.18449 19.8249 4.48692 19.8249H18.8634V9.97458H21V22H4.48692C2.00446 22 0 19.9594 0 17.4322V0Z"
        fill={_color}
      />
    </svg>
  );
};
