import React, { useContext } from "react";
import { db } from "../../Firebase";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../AuthContext";

import "./styles.scss";

function pregPathname(url) {
  return url.match(/^https?:\/{2,}.*?(\/.*)/)[1];
}

export const Term = () => {
  const { currentUser, redirectUrl } = useContext(AuthContext);
  const history = useHistory();

  React.useEffect(() => {
    // SPだとbody要素のスクロールが反応してうまくコンテンツがスクロールできないのを防ぐ
    // document.body.style.overflow = "hidden";
  }, []);

  const handledClick = async () => {
    document.body.style.overflow = "";
    await db.collection("members").doc(currentUser.uid).set(
      {
        isTermCheck: true,
      },
      { merge: true }
    );
    history.push(redirectUrl ? pregPathname(redirectUrl) : "/");
  };
  return (
    <div className="term">
      <div className="term__inner">
        <h1 className="term__title">
          Welcome!
          <br />
          CEKAI MEMBER'S SITE
        </h1>
        <img className="term__logo" src="./logo.svg" alt="" />
        <p className="term__desc">
          ここから先はIDを受け取ったCEKAIメンバーのみが利用できるサイトです。
          <br />
          メンバー規約に同意してサイトにアクセスしてください。
        </p>
        <div className="term__contents-wrap">
          <h2>CEKAIメンバー規約</h2>
          <ul>
            <li>
              運営母体である世界株式会社へ自身の基本情報(名前、メールアドレス)を共有します。
            </li>
            <li>
              CEKAIプロデューサーがクリエーター提案の際に、自身のワークスを使用することを許可します。
            </li>
            <li>本サイトで得た情報は第三者へ漏洩しません。</li>
            <li>
              運営側から退会の提示があった場合は退会します。また、メンバー自身が退会を希望する場合はいつでも退会することができます。
            </li>
          </ul>
        </div>
        <div className="term__button-box">
          <button onClick={handledClick}>同意してはじめる</button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Term);
