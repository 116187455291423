import React, { useState, useEffect } from "react";
import { storage } from "../../Firebase";

import "./styles.scss";

/**
 * ファイルサイズを適切な単位に変換
 * @param {number} size
 * @return {*}
 */
const UnitChangeForByte = (size) => {
  const { target, unit } = getTarget(size);
  const newSize =
    target !== null
      ? Math.floor((size / target) * Math.pow(10, 2)) / Math.pow(10, 2)
      : size;

  return newSize + unit;
};

/**
 * 入力されたファイルサイズを元に計算につかうバイト数と単位を返却
 * @param {number} size
 * @return {{target, unit}}
 */
function getTarget(size) {
  const kb = 1024;
  const mb = Math.pow(kb, 2);
  const gb = Math.pow(kb, 3);
  const tb = Math.pow(kb, 4);

  const returnData = (target, unit) => ({ target, unit });

  if (size >= tb) return returnData(tb, "TB");
  if (size >= gb) return returnData(gb, "GB");
  if (size >= mb) return returnData(mb, "MB");
  if (size >= kb) return returnData(kb, "KB");

  return returnData(null, "byte");
}

/**
 * 拡張子を取得する
 * @param {number} filename
 * @return {{mime}}
 */
function get_extension(filename) {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
}

function getExtension(data) {
  const extension = get_extension(data.name);
  let type = "file";
  if (
    extension === "jpg" ||
    extension === "png" ||
    extension === "gif" ||
    extension === "ico" ||
    extension === "webbp"
  ) {
    type = "image";
  }

  return type;
}

export const FileItem = (props) => {
  const { data } = props;
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [size, setSize] = useState(UnitChangeForByte(0));
  const [extension, setExtension] = useState(getExtension(data));

  useEffect(() => {
    let unmounted = false;
    const f = async () => {
      const ref = storage.ref(data.fullPath);

      setExtension(() => {
        const extension = get_extension(data.name);
        let type = "file";
        if (
          extension === "jpg" ||
          extension === "png" ||
          extension === "gif" ||
          extension === "ico" ||
          extension === "webbp"
        ) {
          type = "image";
        }

        return type;
      });

      await ref
        .getDownloadURL()
        .then(function (url) {
          if (!unmounted) {
            const xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            xhr.onload = (event) => {
              const blob = xhr.response;
              setDownloadUrl(URL.createObjectURL(blob));
            };
            xhr.open("GET", url);
            xhr.send();
            setThumbnail(url);
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      await ref.getMetadata().then((res) => {
        if (!unmounted) {
          setSize(UnitChangeForByte(res.size));
        }
      });
    };
    f();
    const cleanup = () => {
      unmounted = true;
    };
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="resources-item--file">
      <div className="resources-item__image-box">
        {thumbnail &&
          (extension === "image" ? (
            <img src={thumbnail} alt={data.name} />
          ) : (
            <svg
              width="120"
              height="139"
              viewBox="0 0 120 139"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M69.5 51V51.5H70H119.5V138H0.5V0.5H69.5V51ZM70.5 50.5V1.22421L118.81 50.5H70.5Z"
                stroke="white"
              />
              <rect x="10" y="19" width="52" height="1" fill="white" />
              <rect x="10" y="30" width="52" height="1" fill="white" />
              <rect x="10" y="41" width="52" height="1" fill="white" />
              <rect x="10" y="52" width="52" height="1" fill="white" />
              <rect x="10" y="63" width="100" height="1" fill="white" />
              <rect x="10" y="74" width="100" height="1" fill="white" />
              <rect x="10" y="85" width="100" height="1" fill="white" />
              <rect x="10" y="96" width="100" height="1" fill="white" />
              <rect x="10" y="107" width="100" height="1" fill="white" />
              <rect x="10" y="118" width="100" height="1" fill="white" />
            </svg>
          ))}
      </div>
      <div className="resources-item__info">
        <p className="resources-item__name">{data.name}</p>
        <span className="resources-item__byte">{size}</span>

        <a
          className="resources-item__download-button"
          href={downloadUrl}
          download={data.name}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="15.5" fill="black" stroke="#525252" />
            <path d="M10 17H8V22H22V17H20" stroke="white" />
            <path d="M16 8.5V18L12.5 14.5" stroke="white" />
          </svg>
        </a>
      </div>
    </div>
  );
};
