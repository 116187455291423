import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../AuthContext";

/* module */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import {
  faTwitter,
  faFacebookF,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import "./styles.scss";

export const MemberCard = (props) => {
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const { data } = props;

  const getSocialIcon = (type) => {
    let icon = null;
    switch (type) {
      case "instagram":
        icon = <FontAwesomeIcon icon={faInstagram} />;
        break;
      case "facebook":
        icon = <FontAwesomeIcon icon={faFacebookF} />;
        break;
      case "twitter":
        icon = <FontAwesomeIcon icon={faTwitter} />;
        break;
      case "web":
        icon = <FontAwesomeIcon icon={faGlobe} />;
        break;
      case "mail":
        icon = <FontAwesomeIcon icon={faEnvelope} />;
        break;
      default:
        break;
    }

    return icon;
  };

  return (
    <div className="member-col member-card">
      <div className="member-card__head">
        <div className="member-card__name-wrap">
          <p className="member-card__name">{data.displayName}</p>
          <p className="member-card__name member-card__name--ja">
            {data.familyNameJa} {data.firstNameJa}
          </p>
        </div>
        {currentUser.uid === data.uid && (
          <button
            className="member-card__edit-button"
            onClick={() => history.push(`/profile/edit`)}
          >
            Edit
          </button>
        )}
      </div>
      <div className="member-card__meta">
        {data.occupation && (
          <ul className="role__list">
            {data.occupation.map((role) => (
              <li className="role__item" key={role}>
                {role}
              </li>
            ))}
          </ul>
        )}
        <img
          className="member-card__avatar"
          src={data.avatar || "/avatar.svg"}
          alt=""
        />
      </div>
      {
        <div className="member-card__contact-container">
          {data.socialList &&
            data.socialList.map((soc, i) => {
              return (
                <a
                  key={i}
                  href={soc.type === "mail" ? `mailto:${soc.link}` : soc.link}
                  className="member-card__contact-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getSocialIcon(soc.type)}
                </a>
              );
            })}

          {
            /*
          ソーシャルリンクは個別に持たずに、socialListとして配列で持つことになったので、
          ここから下の処理は古いデータが残ってる場合のフォールバック
           */
            data.instagram && (
              <a
                href={data.instagram}
                className="member-card__contact-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            )
          }
          {data.twitter && (
            <a
              href={data.twitter}
              className="member-card__contact-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          )}
          {data.facebook && (
            <a
              href={data.facebook}
              className="member-card__contact-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          )}
          {data.web && (
            <a
              href={data.web}
              className="member-card__contact-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faGlobe} />
            </a>
          )}
          {data.contactEmail && (
            <a
              href={`mailto:${data.contactEmail}`}
              className="member-card__contact-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          )}
        </div>
      }
    </div>
  );
};
