import React, { useContext } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { AuthContext } from "../../../AuthContext";
import { BottomNavContext } from "../../../BottomNavContext";

import SPMenu from "../SPMenu";
import { ICalendar, IPost } from "../Icon";

import "./styles.scss";

export const SPHeader = () => {
  const { currentUser } = useContext(AuthContext);
  const { showBottomNav } = useContext(BottomNavContext);

  const [isOpen, setIsOpen] = React.useState(false);
  const [isPopUpShow, setIsPopUpShow] = React.useState(true);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsPopUpShow(false);
    }, 4000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showBottomNav]);

  return (
    <React.Fragment>
      <header className="sp-header">
        <div className="sp-header__menu" onClick={() => setIsOpen(true)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <Link to="/">
          <svg
            width="45"
            height="30"
            viewBox="0 0 52 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.05387 10.8225C6.09904 10.0362 6.27976 9.3425 6.64118 8.695C7.45439 7.1225 9.0808 6.0125 10.9331 6.0125C12.7854 6.0125 14.4118 7.1225 15.225 8.695C15.5864 9.3425 15.7672 10.0362 15.8123 10.8225C15.8123 10.8225 15.8123 10.8225 15.8123 10.8688L6.05387 10.8225C6.05387 10.8687 6.05387 10.8687 6.05387 10.8225ZM32.98 16.3262C32.8445 19.055 30.6308 21.2288 27.8749 21.2288C25.119 21.2288 22.9053 19.055 22.7698 16.3262H32.98ZM45.5395 10.8688C45.2233 22.8012 36.5491 30.6638 25.7967 30.6638C16.8966 30.6638 9.35187 24.605 7.00261 16.3262H16.5352C17.3032 22.57 21.821 26.8713 27.7845 26.825C40.7507 26.6863 40.1182 10.8688 40.1182 10.8688H22.2276C21.9114 4.995 16.8966 0 10.9331 0C4.92441 0 0.0451781 4.9025 0 10.915C0 10.9613 0 10.9612 0 11.0075V11.0537C0.0451781 25.345 11.6108 37 25.7967 37C40.0278 37 51.1416 26.1313 52 10.8688H45.5395Z"
              fill="white"
            />
          </svg>
        </Link>
        <Link to="/profile">
          <img
            src={currentUser.avatar || "/avatar.svg"}
            alt=""
            className="sp-header__avatar"
          />
        </Link>

        <SPMenu isOpen={isOpen} setIsOpen={setIsOpen} />
      </header>

      {showBottomNav && (
        <div className="bottomNav">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdsFLN22hNmD3cy3LEWb2CwfpCEtXJAGtlBif2HdRZT8NvNFQ/viewform?embedded=true&gxids=7628"
            className={classNames("bottomNav__button")}
            rel="noopener noreferrer"
          >
            <ICalendar />
            <span
              className={classNames(
                "bottomNav__popup",
                "left",
                isPopUpShow && "isShow"
              )}
            >
              村世界 会議室予約
              <svg
                className={classNames("bottomNav__popup-arrow", "left")}
                width="13"
                height="9"
                viewBox="0 0 13 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 0.999943L7 7.93018L12.5833 0.999942L1 0.999943Z"
                  fill="black"
                />
                <path
                  d="M1 0.999943L7 7.93018L12.5833 0.999942"
                  stroke="white"
                />
              </svg>
            </span>
          </a>
          <Link
            to={{
              pathname: "/post",
              state: {
                postType: null,
              },
            }}
            className={classNames("bottomNav__button")}
          >
            <IPost />
            <span
              className={classNames(
                "bottomNav__popup",
                "right",
                isPopUpShow && "isShow"
              )}
            >
              新規投稿
              <svg
                className={classNames("bottomNav__popup-arrow", "right")}
                width="13"
                height="9"
                viewBox="0 0 13 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 0.999943L7 7.93018L12.5833 0.999942L1 0.999943Z"
                  fill="black"
                />
                <path
                  d="M1 0.999943L7 7.93018L12.5833 0.999942"
                  stroke="white"
                />
              </svg>
            </span>
          </Link>
        </div>
      )}
    </React.Fragment>
  );
};
