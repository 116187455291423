import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../AuthContext";
import { useParams, useLocation } from "react-router-dom";
import { db } from "../../Firebase";

/* styles */
import "./styles.scss";

import ProjectCard from "../../components/ProjectCard";

const perPage = 15;

const PostList = ({ postType = "projects" }) => {
  let location = useLocation();
  let { pid } = useParams();
  const { currentUser } = useContext(AuthContext);
  const postCollection = db
    .collection("posts")
    .where("postType", "==", postType);
  const [projects, setProjects] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const observerTarget = React.useRef(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isEndReach, setIsEndReach] = useState(false);

  useEffect(() => {
    let unmounted = false;

    const f = async () => {
      if (!isFetching) return;

      const firstQuery = postCollection
        .orderBy("created_at", "desc")
        .limit(perPage);
      const query = postCollection
        .orderBy("created_at", "desc")
        .startAfter(lastVisible)
        .limit(perPage);

      const projectList = [];
      const _projects = lastVisible
        ? await query.get()
        : await firstQuery.get();

      const _lastVisible = _projects.docs[_projects.docs.length - 1];

      if (_lastVisible) {
        setLastVisible(_lastVisible);
      } else {
        setIsEndReach(true);
      }

      await Promise.all(
        _projects.docs.map(async (doc) => {
          if (!unmounted) {
            const data = doc.data() || {};
            projectList.push({
              pid: doc.id,
              ...data,
            });
          }
        })
      );

      if (!unmounted) {
        setProjects((prev) => {
          return [...prev, ...projectList];
        });
        setIsFetching(false);
      }
    };
    f();

    const cleanup = () => {
      unmounted = true;
    };
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  useEffect(() => {
    document.querySelector(".contents").scrollTop = 0;
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (!isFetching && !isEndReach) {
        setIsFetching(true);
      }
    });

    observer.observe(observerTarget.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      observer.unobserve(observerTarget.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="projects">
        {projects
          .filter((project) => pid !== project.pid)
          .map((project) => (
            <ProjectCard
              key={project.pid}
              isOwn={currentUser.uid === project.author.uid}
              postType={postType}
              {...project}
            />
          ))}
      </div>
      <div ref={observerTarget} className="observer"></div>
    </React.Fragment>
  );
};
export default PostList;
