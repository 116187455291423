import React from "react";
import "./style.scss";

const PgaeTitle = (props) => {
  const { title, subTitle, rightButton } = props;
  return (
    <div className="pageTitle">
      <h2>
        {title} {subTitle && <p>{subTitle}</p>}
      </h2>
      {rightButton}
    </div>
  );
};

export default PgaeTitle;
