import React from "react";
import "./styles.scss";

export const FolderItem = (props) => {
  const { data, handledClick } = props;
  return (
    <div
      className="resources-item"
      onClick={() => handledClick({ pathName: data.name, path: data.fullPath })}
    >
      <div className="resources-item__image-box">
        <svg
          width="171"
          height="136"
          viewBox="0 0 171 136"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M87.2513 1H1V135H170V19.3922H87.2513V1Z" stroke="white" />
        </svg>
      </div>
      <div className="resources-item__info">
        <p className="resources-item__name">{data.name}</p>
      </div>
    </div>
  );
};
