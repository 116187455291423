import React, { useContext } from "react";
import { AuthContext } from "../../AuthContext";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import { IEdit } from "../common/Icon";

import "./styles.scss";

const getDate = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  return `${year}.${month + 1}.${day + 1}`;
};

const ProjectCard = (props) => {
  const { history, isOwn, postType = "projects", author } = props;
  const { currentUser } = useContext(AuthContext);

  let tag = postType;

  // 投稿タイプが"Projects"か"Recommends"だった場合複数形のsを消す
  switch (postType) {
    case "projects":
      tag = "project";
      break;
    case "recommends":
      tag = "recommend";
      break;
    default:
      break;
  }

  return (
    <Link to={`/${postType}/${props.pid}`} className="project-card">
      <div className="project-card__title-box">
        <h3 className="project-card__title">{props.title}</h3>
        <div className="project-card__meta">
          <p className="project-card__post-type">#{tag}</p>
          <p className="project-card__date">
            {getDate(props.created_at.toDate() || props.created_at) || ""}
          </p>
        </div>
      </div>
      <div className="project-card__author">
        <img
          src={author.avatar || "/avatar.svg"}
          alt=""
          className="project-card__avatar"
        />
        <p className="project-card__author-name">
          {author && author.displayName ? author.displayName : "anonymous"}
        </p>
      </div>
      <div className="project-card__contents">
        {props.mainvisual ? (
          <img
            className="project-card__mainvisual"
            src={props.mainvisual}
            alt=""
          />
        ) : (
          <div
            className="project-card__explain"
            dangerouslySetInnerHTML={{ __html: props.contents }}
          ></div>
        )}
      </div>
      {(currentUser.role === "admin" || isOwn) && (
        <button
          className="project-card__edit-button"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/${postType}/${props.pid}/edit`);
          }}
        >
          <IEdit size="s" />
          <span>Edit</span>
        </button>
      )}
    </Link>
  );
};

export default withRouter(ProjectCard);
