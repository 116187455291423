import React from "react";
import { AuthContext } from "../../AuthContext";

import { ContentState, EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import moment from "moment-timezone";

import firebase from "firebase/app";
import { db } from "../../Firebase";

/* components */
import ReplyComment from "../PostComment/reply";
import { IEdit } from "../common/Icon";

import "./styles.scss";
import "./editor.scss";

const CommentBlock = ({ data, replyList = [] }) => {
  const { currentUser } = React.useContext(AuthContext);
  const dataPath = data.path;
  const [isEdit, setIsEdit] = React.useState(false);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const onEditorStateChange = (editorState) => setEditorState(editorState);
  React.useEffect(() => {
    const contentBlock = htmlToDraft(data.comment);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const date = moment(data.created_at.toDate());
  date.tz("Asia/Tokyo");
  const timeFromNow = date.fromNow();

  const isOwner =
    data.author.uid === currentUser.uid || currentUser.role === "admin";

  const onDelete = async () => {
    const res = window.confirm(`この操作は取り消せません。\nよろしいですか？`);

    if (res) {
      try {
        await db.doc(dataPath).delete();
        alert("コメントを削除しました");
        setIsEdit(false);
      } catch (e) {
        console.error(e);
        alert(e);
      }
    }
  };

  const onUpdate = async () => {
    if (!editorState.getCurrentContent().hasText()) {
      alert("コメントを入力してください");
      return;
    }

    try {
      await db.doc(dataPath).set(
        {
          comment: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
        },
        { merge: true }
      );
      setIsEdit(false);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div className="comment-item">
      <div className="comment-item__left-col">
        <div className="comment-item__avatar">
          <img src={data.author.avatar || "/avatar.svg"} alt="" />
        </div>
        {replyList.length > 0 && <span className="bar"></span>}
      </div>
      <div className="comment-item__text-container">
        <div className="comment-item__user">
          <p className="comment-item__user-name">{data.author.displayName}</p>
          <span className="comment-item__date">{timeFromNow}</span>
          {!isEdit && isOwner && (
            <button
              className="comment-item__edit-button"
              onClick={() => setIsEdit(true)}
            >
              <IEdit size="s" />
              <span className="comment-item__edit-button__label">Edit</span>
            </button>
          )}
        </div>
        <div className="comment-item__contents">
          {isEdit ? (
            <div className="comment-item__edit-wrap">
              <Editor
                editorState={editorState}
                wrapperClassName="edit-comment-editor-wrap"
                editorClassName="edit-comment-editor"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  options: [
                    "inline",
                    "fontSize",
                    "link",
                    "emoji",
                    "list",
                    "textAlign",
                  ],
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: {
                    popupClassName: "tool-bar__popup",
                    inDropdown: true,
                  },
                  emoji: { popupClassName: "tool-bar__popup" },
                }}
              />
              <div className="comment-item__editor-bottom">
                <button
                  onClick={onDelete}
                  className="comment-item__editor-button comment-item__editor-button--remove"
                >
                  Delete
                </button>
                <button
                  className="comment-item__editor-button comment-item__editor-button--cancel"
                  onClick={() => setIsEdit(false)}
                >
                  Cancel
                </button>
                <button
                  className="comment-item__editor-button comment-item__editor-button--update"
                  onClick={onUpdate}
                >
                  Update
                </button>
              </div>
            </div>
          ) : (
            <div
              className="comment-item__text"
              dangerouslySetInnerHTML={{ __html: data.comment }}
            ></div>
          )}
          {replyList.length > 0 &&
            replyList.map((comment) => (
              <CommentBlock key={comment.uid} data={comment} />
            ))}
        </div>
      </div>
    </div>
  );
};

const CommentItem = ({ data }) => {
  const [isReply, setIsReply] = React.useState(false);
  const [replyList, setReplyList] = React.useState([]);

  React.useMemo(() => {
    const col = db
      .doc(data.path)
      .collection("reply")
      .orderBy("created_at", "asc");

    // 更新イベント監視
    const unsubscribe = col.onSnapshot(async (res) => {
      const list = [];
      if (!res.empty) {
        await Promise.all(
          res.docs.map(async (doc) => {
            if (doc.exists) {
              const uid = doc.id;
              const _data = doc.data();
              const path = doc.ref.path;
              list.push({ uid, ..._data, path });
            }
          })
        );
      }
      setReplyList(list);
    });
    return unsubscribe;
  }, [data]);

  // React.useEffect(() => {
  //   return unsubscribe();
  // }, []);

  return (
    <div className="comment-item__wrap">
      <CommentBlock data={data} replyList={replyList} />
      {isReply ? (
        <ReplyComment parentData={data} onCancel={() => setIsReply(false)} />
      ) : (
        <button
          onClick={() => setIsReply(true)}
          className="comment-item__reply-button"
        >
          Reply
        </button>
      )}
    </div>
  );
};
export default CommentItem;
