import React from "react";
import { Switch, Route, Link } from "react-router-dom";
import { db } from "../../Firebase";

/* components */
import PageTitle from "../../components/common/PageTitle";

import { QandADetail } from "./Detail";

import "./styles.scss";

export const QandA = () => {
  const [qandaList, setQandaList] = React.useState([]);

  React.useEffect(() => {
    const unsubscribe = db
      .collection("ura-cms")
      .doc("qanda")
      .collection("list")
      .orderBy("order", "asc")
      .onSnapshot((res) => {
        const list = res.docs.map((data) => {
          return { id: data.id, ...data.data() };
        });
        setQandaList(list);
      });
    return unsubscribe;
  }, []);
  return (
    <div className="contents">
      <PageTitle title={"Q&A"} subTitle={"よくある質問"} />
      <Switch>
        <Route exact path={`/qanda`}>
          {qandaList.length > 0 &&
            qandaList.map((data) => {
              return (
                <div key={data.id} className="qanda__link-item">
                  <Link to={`/qanda/${data.id}`}>{data.title}</Link>
                </div>
              );
            })}
        </Route>
        <Route path={`/qanda/:slug`}>
          <QandADetail />
        </Route>
      </Switch>
    </div>
  );
};
