import React, { useContext } from "react";
import classNames from "classnames";
import { AuthContext } from "../../AuthContext";
import { useMediaQuery } from "react-responsive";

/* components */
import HomePostList from "../../components/HomePostList";
import { HeadPostButton } from "../../components/HeadButton";

import "./styles.scss";
export const Home = () => {
  const { currentUser } = useContext(AuthContext);
  const isMobile = useMediaQuery({ maxWidth: 1018 });
  const perPage = isMobile ? 10 : 15;
  const [postType, setPostType] = React.useState(null);

  return (
    <div
      className="contents"
      style={{ paddingBottom: isMobile ? "80px" : "0" }}
    >
      <div className="headContainer">
        <h1 className="headContainer__txt">Hello,{currentUser.displayName}</h1>
        <h1 className="headContainer__txt headContainer__txt--sub">
          Hello,{currentUser.displayName}
          {"    "}
        </h1>
        <h1 className="headContainer__txt headContainer__txt--sub2">
          Hello,{currentUser.displayName}
          {"    "}
        </h1>
      </div>

      <div className="home-head">
        <div className="home-head__tag-list">
          <div
            className={classNames([
              "home-head__tag-item",
              postType === null && "is-active",
            ])}
            onClick={() => setPostType(null)}
          >
            All
          </div>
          <div
            className={classNames([
              "home-head__tag-item",
              postType === "projects" && "is-active",
            ])}
            onClick={() => setPostType("projects")}
          >
            Project
          </div>
          <div
            className={classNames([
              "home-head__tag-item",
              postType === "wanted" && "is-active",
            ])}
            onClick={() => setPostType("wanted")}
          >
            Wanted
          </div>
          <div
            className={classNames([
              "home-head__tag-item",
              postType === "recommends" && "is-active",
            ])}
            onClick={() => setPostType("recommends")}
          >
            Recommend
          </div>
          <div
            className={classNames([
              "home-head__tag-item",
              postType === "information" && "is-active",
            ])}
            onClick={() => setPostType("information")}
          >
            Information
          </div>
        </div>
        {isMobile || <HeadPostButton postType={postType} />}
      </div>

      <div className="home-post-list-row">
        <HomePostList postType={postType} perPage={perPage} />
      </div>
    </div>
  );
};
