import React from "react";
import { Switch, Route, Link } from "react-router-dom";
import { db } from "../../Firebase";

/* components */
import PageTitle from "../../components/common/PageTitle";

import { ServiceDetail } from "./Detail";

import "./styles.scss";

export const Service = () => {
  const [serviceList, setSeviceList] = React.useState([]);

  React.useEffect(() => {
    const unsubscribe = db
      .collection("ura-cms")
      .doc("service")
      .collection("list")
      .orderBy("order", "asc")
      .onSnapshot((res) => {
        const list = res.docs.map((data) => {
          return { id: data.id, ...data.data() };
        });
        setSeviceList(list);
      });
    return unsubscribe;
  }, []);
  return (
    <div className="contents">
      <PageTitle title={"Service"} subTitle={"利用可能サービス"} />
      <Switch>
        <Route exact path={`/service`}>
          {serviceList.length > 0 &&
            serviceList.map((data) => {
              return (
                <div key={data.id} className="qanda__link-item">
                  <Link to={`/service/${data.id}`}>{data.title}</Link>
                </div>
              );
            })}
        </Route>
        <Route path={`/service/:slug`}>
          <ServiceDetail />
        </Route>
      </Switch>
    </div>
  );
};
