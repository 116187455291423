import React, { useContext } from "react";
import { AuthContext } from "./AuthContext";

export const PrivateRoute = ({ ...props }) => {
  const { currentUser, isUserCheck } = useContext(AuthContext);
  if (!isUserCheck) {
    return <div></div>;
  }

  return currentUser ? props.children : <div></div>;
};
