import React, { useState, useCallback, useContext } from "react";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

import firebase from "firebase/app";
import "firebase/storage";
import { db, storage } from "../../Firebase";
import { useMediaQuery } from "react-responsive";

import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { AuthContext } from "../../AuthContext";
import { useDropzone } from "react-dropzone";

/* components */
import PageTitle from "../../components/common/PageTitle";
import { Add } from "../../components/common/Icon";

/* styles */
import "./styles.scss";
import "./editor.scss";
import { useEffect } from "react";
const acceptFile = "image/*";
const maxFileSize = 10048576; //MAX 10MB

const Post = ({ _postType = "projects", history }) => {
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);
  const [postType, setPostType] = useState(location.state.postType || "");
  const [pid] = useState(db.collection(postType || "projects").doc().id);

  const [title, setTitle] = useState("");
  const [files, setFiles] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0]) {
      Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
      setFiles(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptFile,
    minSize: 0,
    maxSize: maxFileSize,
  });

  const onUpload = async ({ pid, file }) => {
    function uploadImageAsPromise(file) {
      const file_name = file.name;
      const storageRef = storage.ref(`posts/${pid}/`).child(`${file_name}`);
      return new Promise(function (resolve, reject) {
        //Upload file
        const task = storageRef.put(file);

        //Update progress bar
        task.on(
          "state_changed",
          (snapshot) => {
            console.log("snapshot", snapshot);
          },
          (error) => {
            console.log("err", error);
          },
          () => {
            // 成功時
            console.log("upload complete.");
            task.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              console.log("File available at", downloadURL);
              resolve(downloadURL);
            });
          }
        );
      })
        .then(function (downloadURL) {
          return downloadURL;
        })
        .catch(function () {
          console.log("Error:uploadImageAsPromise");
        });
    }
    const result = await uploadImageAsPromise(file);
    return result;
  };

  const onEditorStateChange = (editorState) => setEditorState(editorState);

  const handleClick = async () => {
    if (!postType) {
      alert("投稿するカテゴリーを選択してください");
      return;
    }
    if (!title) {
      alert("タイトルを入力してください");
      return;
    } else if (!editorState.getCurrentContent().hasText()) {
      alert("コンテンツを入力してください");
      return;
    }
    try {
      await add();
      history.push(`/${postType}`);
    } catch (e) {
      console.log(e);
    }
  };

  const add = useCallback(async () => {
    try {
      const postRef = db.collection("posts");
      const mainvisual = files ? await onUpload({ pid, file: files }) : "";
      await postRef.doc(pid).set({
        pid,
        title,
        postType,
        mainvisual,
        author: {
          ...currentUser,
        },
        contents: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        created_at: firebase.firestore.Timestamp.fromDate(new Date()),
      });
    } catch (e) {
      console.log(e);
    }
  }, [currentUser, files, pid, postType, title, editorState]);

  const uploadEditorImage = async (file) => {
    const link = await onUpload({ pid, file });
    return { data: { link: link } };
  };

  useEffect(() => {
    document.querySelector(".contents").scrollTop = 0;
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contents">
      <PageTitle
        title={"Post"}
        subTitle={"CEKAIメンバーにシェアしたいコトを投稿"}
      />
      <div className="post-project">
        <span className="post-project__label">Catgeory</span>
        <div className="category-select__wrap">
          <span className="category-select__hash">#</span>
          <select
            className={classNames("category-select", {
              "is-placholder": !postType,
            })}
            value={postType}
            name="category"
            onChange={(event) => {
              event.persist();
              setPostType(event.target.value);
            }}
          >
            <option value="" disabled>
              Select your catgory
            </option>
            <option value="projects">Project</option>
            <option value="wanted">Wanted</option>
            <option value="recommends">Recommend</option>
            <option value="information">Information</option>
          </select>
        </div>
        <span className="post-project__label">Title</span>
        <input
          className="post-project__input"
          type="text"
          value={title}
          placeholder="Fill your title."
          onChange={(e) => setTitle(e.target.value)}
        />
        <span className="post-project__label">Mainvisual</span>
        <div className="dropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : files ? (
            <img src={files.preview} alt="" />
          ) : (
            <div className="dropzone-attention">
              <svg
                className="dropzone-attention__icon"
                width="73"
                height="73"
                viewBox="0 0 73 73"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="36.5"
                  cy="36.5"
                  r="36"
                  fill="black"
                  stroke="white"
                />
                <line x1="57" y1="37.5" x2="13" y2="37.5" stroke="white" />
                <line x1="36.5" y1="58" x2="36.5" y2="15" stroke="white" />
              </svg>

              <p className="dropzone-attention__desc">
                {isMobile ? "Add mainvisual" : "Drag and Drop or browse"}
              </p>
            </div>
          )}
        </div>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="editor__wrap"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: [
              "inline",
              "fontSize",
              "link",
              "emoji",
              "image",
              "list",
              "textAlign",
            ],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { popupClassName: "tool-bar__popup", inDropdown: true },
            emoji: { popupClassName: "tool-bar__popup" },
            image: {
              popupClassName: "tool-bar__popup",
              uploadCallback: uploadEditorImage,
              alt: { present: false, mandatory: false },
              defaultSize: {
                height: "auto",
                width: "100%",
              },
            },
            history: { inDropdown: true },
          }}
        />
        <button className="post-project__submit" onClick={handleClick}>
          <Add />
          <span>Post</span>
        </button>
      </div>
    </div>
  );
};
export default withRouter(Post);
