import React, { useContext } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";

import { AuthContext } from "../../AuthContext";
import firebase, { db } from "../../Firebase";

import { FaFacebookF, FaTwitter, FaInstagram, FaGlobe } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

/* components */
import PageTitle from "../../components/common/PageTitle";
import { IEdit } from "../../components/common/Icon";

import { EditProfile } from "./edit";

import "./styles.scss";
export const Profile = () => {
  const history = useHistory();
  let match = useRouteMatch();
  const { currentUser } = useContext(AuthContext);

  const handleEdit = () => {
    history.push(`/profile/edit`);
  };

  const initializeSocialList = async (ref, data) => {
    const socialList = [];

    if (data.instagram !== "") {
      socialList.push({ type: "instagram", link: data.instagram });
    }
    if (data.twitter !== "") {
      socialList.push({ type: "twitter", link: data.twitter });
    }
    if (data.facebook !== "") {
      socialList.push({ type: "facebook", link: data.facebook });
    }
    if (data.web !== "") {
      socialList.push({ type: "web", link: data.web });
    }
    if (data.contactEmail !== "") {
      socialList.push({ type: "mail", link: data.contactEmail });
    }

    if (socialList.length > 0) {
      await ref.update({
        socialList,
        instagram: firebase.firestore.FieldValue.delete(),
        twitter: firebase.firestore.FieldValue.delete(),
        facebook: firebase.firestore.FieldValue.delete(),
        web: firebase.firestore.FieldValue.delete(),
        contactEmail: firebase.firestore.FieldValue.delete(),
      });
    }
  };

  const snsIcon = (type) => {
    let icon = null;
    switch (type) {
      case "facebook":
        icon = <FaFacebookF />;
        break;
      case "twitter":
        icon = <FaTwitter />;
        break;
      case "instagram":
        icon = <FaInstagram />;
        break;
      case "web":
        icon = <FaGlobe />;
        break;
      case "mail":
        icon = <FiMail />;
        break;
      default:
        icon = <FaGlobe />;
    }

    return icon;
  };

  React.useEffect(() => {
    const unsubscribe = async () => {
      const { uid, socialList } = currentUser;
      if (!socialList) {
        const ref = db.collection("members").doc(uid);
        await initializeSocialList(ref, currentUser);
      }
    };
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Switch>
      <Route path={`${match.path}/edit`}>
        <EditProfile />
      </Route>
      <Route path={`${match.path}`}>
        <div className="contents">
          <PageTitle
            title={"Profile"}
            rightButton={
              <div onClick={handleEdit} className="edit-button">
                <IEdit size="m" color="black" />
                <span>Edit</span>
              </div>
            }
          />
          <div className="profile__contents-area">
            <div className="profile__head">
              <div className="avatar">
                <img src={currentUser.avatar || "/avatar.svg"} alt="" />
              </div>
              <div className="name-area">
                <h3 className="name">{currentUser.displayName}</h3>
                <p className="name-ja">
                  {currentUser.familyNameJa} {currentUser.firstNameJa}
                </p>
                <p className="name-ja">
                  {currentUser.familyName} {currentUser.firstName}
                </p>
              </div>
            </div>

            <div className="profile__body">
              <div className="profile__block">
                <h4 className="title">Role</h4>
                <ul className="occupation-list">
                  {currentUser.occupation &&
                    currentUser.occupation.map((ocu) => (
                      <li key={ocu}>{ocu}</li>
                    ))}
                </ul>
              </div>
              <div className="profile__block">
                <h4 className="title">SNS</h4>
                <ul className="sns-list">
                  {currentUser.socialList &&
                    currentUser.socialList.map((sc, i) => {
                      return (
                        <li key={i} className="item">
                          <i>{snsIcon(sc.type)}</i>
                          {sc.link}
                        </li>
                      );
                    })}
                </ul>
              </div>
              <button onClick={handleEdit} className="submit">
                <IEdit size="m" color="black" />
                <span className="submit__label">Edit</span>
              </button>
            </div>
          </div>
        </div>
      </Route>
    </Switch>
  );
};
