import React, { useEffect, useState } from "react";
import { BottomNavContext } from "./BottomNavContext";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";

export const BottomNavProvider = ({ children }) => {
  const [showBottomNav, setShowBottomNav] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    switch (true) {
      case /\/post/.test(path):
        setShowBottomNav(false);
        break;
      case /\/edit/.test(path):
        setShowBottomNav(false);
        break;
      case path === "/":
        setShowBottomNav(true);
        break;
      case /\/projects/.test(path):
        setShowBottomNav(true);
        break;
      case /\/recommend/.test(path):
        setShowBottomNav(true);
        break;
      case /\/wanted/.test(path):
        setShowBottomNav(true);
        break;
      default:
        setShowBottomNav(false);
        break;
    }
  }, [location]);
  return (
    <BottomNavContext.Provider
      value={{
        showBottomNav,
      }}
    >
      {children}
    </BottomNavContext.Provider>
  );
};
export default withRouter(BottomNavProvider);
