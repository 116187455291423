import React from "react";
import CommentItem from "../CommentItem";

const CommentList = ({ data }) => {
  return (
    <div>
      {data.length > 0 &&
        data.map((comment) => {
          return <CommentItem key={comment.uid} data={comment} />;
        })}
    </div>
  );
};
export default CommentList;
