import React, { useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";

import firebase, { db } from "./Firebase";

/**
 * Get the URL parameter value
 *
 * @param  name {string} パラメータのキー文字列
 * @return  url {url} 対象のURL文字列（任意）
 */
function getParam(name, url) {
  if (!url) url = window.location.href;
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function pregPathname(url) {
  return url.match(/^https?:\/{2,}.*?(\/.*)/)[1];
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isUserCheck, setIsUserCheck] = useState(false);
  const [credential, setCredential] = useState(getParam("credential"));
  const [redirectUrl] = useState(getParam("redirect_url") || null);

  const [isLogout, setIsLogout] = useState(false);
  const history = useHistory();

  const setUser = async (user) => {
    const data = await db.collection("members").doc(user.uid).get();
    if (data.exists) {
      setCurrentUser({ uid: user.uid, ...data.data() });
      setIsUserCheck(true);
    }
  };

  const login = async (email, password, history) => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      history.push(redirectUrl ? pregPathname(redirectUrl) : "/");
    } catch (e) {
      alert(e);
    }
  };

  const logout = async () => {
    try {
      await firebase.auth().signOut();
      setIsLogout(true);
      setIsUserCheck(false);
      setCurrentUser(null);
      window.location.href = `${process.env.REACT_APP_CORPORATE_SITE}/#login`;
    } catch (e) {
      alert(e);
    }
  };

  const redirectToLogin = () => {
    window.location.href =
      window.location.pathname === "/"
        ? `${process.env.REACT_APP_CORPORATE_SITE}/#login`
        : `${process.env.REACT_APP_CORPORATE_SITE}/?redirect_url=${window.location.href}#login`;
  };

  const checkCredential = React.useCallback(async () => {
    const ref = db.collection("auth").doc(credential);
    return await ref.get().then(async (res) => {
      if (res.exists) {
        const { credential } = res.data();
        await firebase
          .auth()
          .signInWithEmailAndPassword(credential.email, credential.password);

        await ref.delete();
        return true;
      } else {
        redirectToLogin();
        return false;
      }
    });
  }, [credential]);

  useEffect(() => {
    let unmounted = false;

    const f = async () => {
      if (unmounted) return;
      if (credential) {
        await checkCredential();
      }
    };
    f();

    const cleanup = () => {
      unmounted = true;
    };
    return cleanup;
  }, [credential, checkCredential]);

  useEffect(() => {
    let unmounted = false;

    const f = async () => {
      if (!currentUser || unmounted) return;

      if (currentUser.isTermCheck === false) {
        history.push("/term");
      } else if (credential) {
        history.push(redirectUrl ? pregPathname(redirectUrl) : "/");
        setCredential(null);
      }
    };
    f();

    const cleanup = () => {
      unmounted = true;
    };
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (isLogout) return;
      if (user) {
        await setUser(user);
      }

      if (!user && !credential) {
        redirectToLogin();
      }
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isUserCheck) return;
    if (currentUser.uid === null) return;
    const { uid } = currentUser;
    const unsubscribe = db
      .collection("members")
      .doc(uid)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setCurrentUser({ uid: uid, ...doc.data() });
        }
      });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserCheck]);

  return (
    <AuthContext.Provider
      value={{
        redirectUrl,
        login,
        logout,
        isUserCheck,
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default withRouter(AuthProvider);
