import React, { useContext } from "react";
import { AuthContext } from "../../AuthContext";
import { db } from "../../Firebase";

import ProjectCard from "../../components/ProjectCard";

import "./styles.scss";

const HomePostList = ({ postType = null, perPage = 4 }) => {
  const { currentUser } = useContext(AuthContext);
  const observerTarget = React.useRef(null);
  const [posts, setPosts] = React.useState([]);
  const [lastVisible, setLastVisible] = React.useState(null);
  const [isFetching, setIsFetching] = React.useState(false);
  const [isEndReach, setIsEndReach] = React.useState(false);

  React.useEffect(() => {
    setPosts([]);
    setLastVisible(null);
    setIsEndReach(false);
    setIsFetching(true);
  }, [postType]);

  React.useEffect(() => {
    let unmounted = false;

    const f = async () => {
      if (!isFetching) return;

      const postCollection = postType
        ? db.collection("posts").where("postType", "==", postType)
        : db.collection("posts");
      const query = lastVisible
        ? postCollection
            .orderBy("created_at", "desc")
            .startAfter(lastVisible)
            .limit(perPage)
        : postCollection.orderBy("created_at", "desc").limit(perPage);

      const postList = [];
      const _posts = await query.get();
      const _lastVisible = _posts.docs[_posts.docs.length - 1];

      if (_lastVisible) {
        setLastVisible(_lastVisible);
      } else {
        setIsEndReach(true);
      }

      await Promise.all(
        _posts.docs.map(async (doc) => {
          if (!unmounted) {
            const data = doc.data() || {};
            postList.push({
              pid: doc.id,
              ...data,
            });
          }
        })
      );

      if (!unmounted) {
        setPosts((prev) => {
          return [...prev, ...postList];
        });
        setIsFetching(false);
      }
    };
    f();

    const cleanup = () => {
      unmounted = true;
    };
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        if (!isFetching && !isEndReach) {
          setIsFetching(true);
        }
      }
    });

    observer.observe(observerTarget.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      observer.unobserve(observerTarget.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="projects">
        {posts.map((post) => (
          <ProjectCard
            key={post.pid}
            isOwn={currentUser.uid === post.author.uid}
            postType={postType}
            {...post}
          />
        ))}
      </div>
      <div ref={observerTarget} className="observer"></div>
    </React.Fragment>
  );
};

export default HomePostList;
