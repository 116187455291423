import React, { useState, useEffect } from "react";

/* module */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";

/* components */
import PageTitle from "../../components/common/PageTitle";

import { FileItem } from "../../components/FileItem";
import { FolderItem } from "../../components/FolderItem";

import { storage } from "../../Firebase";

/* style */
import "./styles.scss";

function Resources() {
  const [path, setPath] = useState({ pathName: "Home", path: "" });
  const [directory, setDirectory] = useState([path]);
  const [resource, setResources] = useState([]);

  useEffect(() => {
    const paths = path.path.split("/");
    const _path = paths.map((p, i) => {
      return { pathName: directory[i].pathName, path: directory[i].path };
    });
    setDirectory(_path);

    storage
      .ref(path.path === "" ? "/resources" : path.path)
      .listAll()
      .then(function (res) {
        const list = [];
        res.prefixes.forEach(function (folderRef) {
          list.push({
            type: "folder",
            name: folderRef.name,
            fullPath: folderRef.fullPath,
            ...folderRef,
          });
        });
        res.items.forEach(function (itemRef) {
          list.push({
            type: "file",
            name: itemRef.name,
            fullPath: itemRef.fullPath,
            ...itemRef,
          });
        });
        setResources(list);
      })
      .catch(function (error) {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  const handledClick = (path) => {
    setDirectory((prev) => [...prev, path]);
    setPath(path);
  };

  const changeDirectory = React.useCallback((_path) => {
    setPath(_path);
  }, []);

  return (
    <div className="contents">
      <PageTitle
        title={"Resource"}
        subTitle={"CEKAIの関連素材・資料ダウンロード"}
      />
      <div className="directory">
        <FontAwesomeIcon icon={faFolder} size="xs" />
        {directory.map((path, index) => {
          return (
            <p
              key={path.pathName + index}
              onClick={() => changeDirectory(path)}
            >
              {path.pathName}
            </p>
          );
        })}
      </div>
      <div className="resources">
        {resource.length === 0 && (
          <p className="resources__empty-txt">No File in this directory.</p>
        )}
        {resource.map((data) => {
          const type = data["type"];
          return type === "file" ? (
            <FileItem key={data.name} data={data} />
          ) : (
            <FolderItem
              key={data.name}
              data={data}
              handledClick={handledClick}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Resources;
