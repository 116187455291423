import React from "react";
import firebase from "firebase/app";
import { db } from "../../Firebase";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import { AuthContext } from "../../AuthContext";

import "./styles.scss";

const PostComment = ({ pid = null, postType = "projects" }) => {
  const postRef = db.collection(postType).doc(pid);
  const { currentUser } = React.useContext(AuthContext);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const onEditorStateChange = (editorState) => setEditorState(editorState);
  const onPost = async () => {
    if (!editorState.getCurrentContent().hasText()) {
      alert("コメントを入力してください");
      return;
    }

    try {
      await postRef
        .collection("comments")
        .doc()
        .set({
          pid,
          author: {
            ...currentUser,
          },
          comment: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
        });
      setEditorState(EditorState.createEmpty());
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div className="post-comments">
      <div className="post-comments__avatar">
        <img src={currentUser.avatar || "/avatar.svg"} alt="" />
      </div>
      <div className="post-comments__contents">
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="comment-editor-wrap"
          editorClassName="comment-editor"
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: [
              "inline",
              "fontSize",
              "link",
              "emoji",
              "list",
              "textAlign",
            ],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { popupClassName: "tool-bar__popup", inDropdown: true },
            emoji: { popupClassName: "tool-bar__popup" },
          }}
        />
        <button className="post-comments__submmit" onClick={onPost}>
          Comment
        </button>
      </div>
    </div>
  );
};
export default PostComment;
