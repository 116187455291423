import React from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

import "./styles.scss";

function Menu() {
  const location = useLocation();

  const checkCurrent = (path) => {
    return location.pathname === path;
  };

  return (
    <ul>
      <li className="menu__item">
        <Link
          to="/"
          name="home"
          className={classNames("menu__link", {
            "is-active": checkCurrent("/"),
          })}
        >
          Home
        </Link>
      </li>
      <li className="menu__item">
        <Link
          to="/projects"
          className={classNames("menu__link", {
            "is-active": location.pathname.match(/\/projects/),
          })}
        >
          Project
        </Link>
      </li>
      <li className="menu__item">
        <Link
          to="/wanted"
          className={classNames("menu__link", {
            "is-active": location.pathname.match(/\/wanted/),
          })}
        >
          Wanted
        </Link>
      </li>
      <li className="menu__item">
        <Link
          to="/recommends"
          className={classNames("menu__link", {
            "is-active": location.pathname.match(/\/recommends/),
          })}
        >
          Recommend
        </Link>
      </li>

      <li className="menu__item">
        <Link
          to="/information"
          className={classNames("menu__link", {
            "is-active": location.pathname.match(/\/information/),
          })}
        >
          Information
        </Link>
      </li>

      <li className="menu__item">
        <Link
          to="/member"
          className={classNames("menu__link", {
            "is-active": checkCurrent("/member"),
          })}
        >
          Member
        </Link>
      </li>

      <li className="menu__item">
        <Link
          to="/service"
          className={classNames("menu__link", {
            "is-active": location.pathname.match(/\/service/),
          })}
        >
          Service
        </Link>
      </li>

      <li className="menu__item">
        <Link
          to="/resources"
          className={classNames("menu__link", {
            "is-active": checkCurrent("/resources"),
          })}
        >
          Resource
        </Link>
      </li>
      <li className="menu__item">
        <Link
          to="/qanda"
          className={classNames("menu__link", {
            "is-active": location.pathname.match(/\/qanda/),
          })}
        >
          Q&A
        </Link>
      </li>
    </ul>
  );
}

export default Menu;
