import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { PrivateRoute } from "./PrivateRoute";
import AuthProvider from "./Auth";
import BottomNavProvider from "./BottomNav";
import "./App.scss";

/* module */

/* common */
import Menu from "./components/common/Menu";
import { Sidebar } from "./components/common/Sidebar";
import { SPHeader } from "./components/common/SPHeader";

import { Mobile, Default } from "./components/common/MediaQuery";

/* views */
import { Term } from "./view/Term";

import { Home } from "./view/Home";
import Member from "./view/Member";
import Resources from "./view/Resources";
import Posts from "./view/Posts";
import Post from "./view/Post";
import { Profile } from "./view/Profile";

import { Service } from "./view/Service";
import { QandA } from "./view/QandA";

const setFillHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

export default function App() {
  const router = React.useRef(null);

  React.useEffect(() => {
    setFillHeight();
    window.addEventListener("resize", setFillHeight);

    router.current.history.listen((location) => {
      if (process.env.GA !== "") {
        window.gtag("config", process.env.GA, {
          page_path: location.pathname,
        });
      }
    });
    return () => window.removeEventListener("resize", setFillHeight);
  }, []);

  return (
    <Router ref={router}>
      <AuthProvider>
        <PrivateRoute>
          <BottomNavProvider>
            <Route exact path="/term">
              <Term />
            </Route>
            <div id="app">
              <Mobile>
                <SPHeader />
              </Mobile>
              <Default>
                <Sidebar />
                <nav className="menu">
                  <Menu />
                </nav>
              </Default>
              <Route exact name="home" path="/">
                <Home />
              </Route>
              <Route name="projects" path="/projects">
                <Posts postType="projects" />
              </Route>
              <Route name="Wanted" path="/wanted">
                <Posts postType="wanted" />
              </Route>
              <Route name="Recommend" path="/recommends">
                <Posts postType="recommends" />
              </Route>
              <Route name="Information" path="/information">
                <Posts postType="information" />
              </Route>
              <Route path="/member">
                <Member />
              </Route>
              <Route path="/service">
                <Service />
              </Route>
              <Route exact path="/resources">
                <Resources />
              </Route>
              <Route path="/qanda">
                <QandA />
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/post">
                <Post />
              </Route>
            </div>
          </BottomNavProvider>
        </PrivateRoute>
        <Route path="/logout">
          <div></div>
        </Route>
      </AuthProvider>
    </Router>
  );
}
