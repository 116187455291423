import React from "react";
import { db } from "../../Firebase";

/* components */
import PostComment from "../PostComment";
import CommentList from "../CommentList";

import "./styles.scss";

const Comments = ({ pid = null, postType = "projects" }) => {
  const commentsRef = db.collection(postType).doc(pid).collection("comments");
  const [comments, setComments] = React.useState(null);

  React.useEffect(() => {
    const col = commentsRef.orderBy("created_at", "desc");

    const unsubscribe = col.onSnapshot(async (res) => {
      const list = [];
      if (!res.empty) {
        await Promise.all(
          res.docs.map(async (doc) => {
            if (doc.exists) {
              const uid = doc.id;
              const _data = doc.data();
              const path = doc.ref.path;
              list.push({ uid, ..._data, path });
            }
          })
        );
      }
      setComments(list);
    });
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pid]);

  return (
    <section className="comments">
      <h3 className="comments__title">Comments</h3>
      <PostComment pid={pid} postType={postType} />
      {comments && <CommentList data={comments} />}
    </section>
  );
};
export default Comments;
