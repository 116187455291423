import React, { useContext } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { AuthContext } from "../../../AuthContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";

import { ICalendar, IPost } from "../Icon";

import "./styles.scss";

export const Sidebar = () => {
  const { currentUser, logout } = useContext(AuthContext);

  return (
    <div className="sideBar">
      <Link to="/" className={classNames("sideBar__logo")}>
        <svg
          className={"logo"}
          width="52"
          height="37"
          viewBox="0 0 52 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.05387 10.8225C6.09904 10.0362 6.27976 9.3425 6.64118 8.695C7.45439 7.1225 9.0808 6.0125 10.9331 6.0125C12.7854 6.0125 14.4118 7.1225 15.225 8.695C15.5864 9.3425 15.7672 10.0362 15.8123 10.8225C15.8123 10.8225 15.8123 10.8225 15.8123 10.8688L6.05387 10.8225C6.05387 10.8687 6.05387 10.8687 6.05387 10.8225ZM32.98 16.3262C32.8445 19.055 30.6308 21.2288 27.8749 21.2288C25.119 21.2288 22.9053 19.055 22.7698 16.3262H32.98ZM45.5395 10.8688C45.2233 22.8012 36.5491 30.6638 25.7967 30.6638C16.8966 30.6638 9.35187 24.605 7.00261 16.3262H16.5352C17.3032 22.57 21.821 26.8713 27.7845 26.825C40.7507 26.6863 40.1182 10.8688 40.1182 10.8688H22.2276C21.9114 4.995 16.8966 0 10.9331 0C4.92441 0 0.0451781 4.9025 0 10.915C0 10.9613 0 10.9612 0 11.0075V11.0537C0.0451781 25.345 11.6108 37 25.7967 37C40.0278 37 51.1416 26.1313 52 10.8688H45.5395Z"
            fill="white"
          />
        </svg>
        <span className={"sideBar__popup"}>
          <svg
            className={"sideBar__popup-arrow"}
            width="9"
            height="13"
            viewBox="0 0 9 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7.93023 1L1 7L7.93023 12.5833V1Z" fill="black" />
            <path d="M7.93023 1L1 7L7.93023 12.5833" stroke="white" />
          </svg>
          Home
        </span>
      </Link>
      <Link to="/profile" className={classNames("sideBar__button")}>
        <span className={classNames("sideBar__avatar")}>
          <img src={currentUser.avatar || "/avatar.svg"} alt="" />
        </span>
        <span className={"sideBar__popup"}>
          <svg
            className={"sideBar__popup-arrow"}
            width="9"
            height="13"
            viewBox="0 0 9 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7.93023 1L1 7L7.93023 12.5833V1Z" fill="black" />
            <path d="M7.93023 1L1 7L7.93023 12.5833" stroke="white" />
          </svg>
          プロフィール
        </span>
      </Link>

      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSdsFLN22hNmD3cy3LEWb2CwfpCEtXJAGtlBif2HdRZT8NvNFQ/viewform?embedded=true&gxids=7628"
        className={classNames("sideBar__button")}
        rel="noopener noreferrer"
      >
        <ICalendar />
        <span className={"sideBar__popup"}>
          <svg
            className={"sideBar__popup-arrow"}
            width="9"
            height="13"
            viewBox="0 0 9 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7.93023 1L1 7L7.93023 12.5833V1Z" fill="black" />
            <path d="M7.93023 1L1 7L7.93023 12.5833" stroke="white" />
          </svg>
          村世界 会議室予約
        </span>
      </a>

      <Link
        to={{
          pathname: "/post",
          state: {
            postType: null,
          },
        }}
        className={classNames("sideBar__button")}
      >
        <IPost />
        <span className={"sideBar__popup"}>
          <svg
            className={"sideBar__popup-arrow"}
            width="9"
            height="13"
            viewBox="0 0 9 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7.93023 1L1 7L7.93023 12.5833V1Z" fill="black" />
            <path d="M7.93023 1L1 7L7.93023 12.5833" stroke="white" />
          </svg>
          新規投稿
        </span>
      </Link>

      <div
        className={"logout-button"}
        onClick={(e) => {
          e.preventDefault();
          logout();
        }}
      >
        <FontAwesomeIcon icon={faPowerOff} />
      </div>
    </div>
  );
};
