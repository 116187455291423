import React from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../../AuthContext";
import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";

import "./styles.scss";

function SPMenu(props) {
  const { setIsOpen } = props;
  const location = useLocation();

  const { logout } = React.useContext(AuthContext);

  React.useEffect(() => {
    setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const checkCurrent = (path) => {
    return location.pathname === path;
  };

  return (
    <div className={["menu-sp", props.isOpen && "is-open"].join(" ")}>
      <span
        className="menu-sp__close-button"
        onClick={() => props.setIsOpen(false)}
      ></span>
      <ul>
        <li className="menu-sp__item">
          <Link
            to="/"
            name="home"
            className={classNames("menu-sp__link", {
              "is-active": checkCurrent("/"),
            })}
          >
            Home
          </Link>
        </li>
        <li className="menu-sp__item">
          <Link
            to="/projects"
            className={classNames("menu-sp__link", {
              "is-active": location.pathname.match(/\/projects/),
            })}
          >
            Project
          </Link>
        </li>
        <li className="menu-sp__item">
          <Link
            to="/wanted"
            className={classNames("menu-sp__link", {
              "is-active": location.pathname.match(/\/wanted/),
            })}
          >
            Wanted
          </Link>
        </li>
        <li className="menu-sp__item">
          <Link
            to="/recommends"
            className={classNames("menu-sp__link", {
              "is-active": location.pathname.match(/\/recommends/),
            })}
          >
            Recommend
          </Link>
        </li>
        {/* <li className="menu-sp__item">
          <Link
            to="/communication-tools/home"
            className={classNames("menu-sp__link", {
              "is-active": location.pathname.match(/\/communication-tools/),
            })}
          >
            Communication Tools
          </Link>
        </li> */}
        <li className="menu-sp__item">
          <Link
            to="/member"
            className={classNames("menu-sp__link", {
              "is-active": checkCurrent("/member"),
            })}
          >
            Member
          </Link>
        </li>
        <li className="menu-sp__item">
          <Link
            to="/service"
            className={classNames("menu-sp__link", {
              "is-active": location.pathname.match(/\/service/),
            })}
          >
            Service
          </Link>
        </li>
        <li className="menu-sp__item">
          <Link
            to="/resources"
            className={classNames("menu-sp__link", {
              "is-active": checkCurrent("/resources"),
            })}
          >
            Resource
          </Link>
        </li>
        <li className="menu-sp__item">
          <Link
            to="/qanda"
            className={classNames("menu-sp__link", {
              "is-active": location.pathname.match(/\/qanda/),
            })}
          >
            Q&A
          </Link>
        </li>
      </ul>
      <div
        className={"menu-sp__logout-button"}
        onClick={(e) => {
          e.preventDefault();
          logout();
        }}
      >
        <FontAwesomeIcon icon={faPowerOff} />
        <p>Log Out</p>
      </div>
    </div>
  );
}

export default SPMenu;
