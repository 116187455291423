import React, { useState } from "react";
import lunr from "lunr";

/* components */
import PageTitle from "../../components/common/PageTitle";
import { MemberCard } from "../../components/MemberCard";

import { db } from "../../Firebase";

/* style */
import "./styles.scss";

require("lunr-languages/lunr.stemmer.support.js")(lunr);
require("lunr-languages/tinyseg.js")(lunr);
require("lunr-languages/lunr.ja.js")(lunr);

function Member() {
  const [member, setMember] = useState(null);
  const [memberList, setMemberList] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [searchResult, setSearchResult] = useState(null);

  const [idx, setIdx] = React.useState(null);

  React.useEffect(() => {
    let unmounted = false;
    const f = async () => {
      const list = {
        creator: [],
        producer: [],
        projectmanager: [],
        creatormanager: [],
        officemanager: [],
        other: [],
      };
      const members = [];

      if (!unmounted) {
        const _members = await db.collection("members").get();
        await Promise.all(
          _members.docs.map(async (doc) => {
            if (doc.exists) {
              if (!unmounted) {
                const uid = doc.id;
                const data = doc.data();

                // Cekaiのメンバー以外の人は入れない
                if (
                  data.displayName !== "admin" &&
                  data.displayName !== "Takumi Obayashi"
                ) {
                  members.push({ uid, ...data });
                }
                if (list.hasOwnProperty(data.cekaiOccupation)) {
                  list[data.cekaiOccupation].push({ uid, ...data });
                }
              }
            }
          })
        );

        Object.keys(list).forEach((role) => {
          const _list = {};
          list[role].forEach((data) => {
            const initial =
              data.firstName !== ""
                ? data.firstName.slice(0, 1).toUpperCase()
                : data.displayName.slice(0, 1).toUpperCase();
            if (_list.hasOwnProperty(initial)) {
              _list[initial].push(data);
            } else {
              _list[initial] = [data];
            }
          });
          list[role] = _list;
        });

        setIdx(
          lunr(function () {
            this.ref("uid");
            this.field("displayName");
            this.field("firstName");
            this.field("familyName");
            this.field("firstNameJa");
            this.field("familyNameJa");
            this.field("occupation");
            this.use(lunr.ja);

            members.forEach(function (doc) {
              this.add(doc);
            }, this);
          })
        );
        setMemberList(members);
        setMember(list);
      }
    };
    f();
    const cleanup = () => {
      unmounted = true;
    };
    return cleanup;
  }, []);

  React.useEffect(() => {
    if (idx && keyword !== "") {
      const result = idx.search(`*${keyword}*`);
      const resultList = [];
      if (result.length > 0) {
        result.forEach((member) => {
          const data = memberList.filter((item) => item.uid === member.ref);
          resultList.push(data[0]);
        });

        setSearchResult(resultList);
      } else {
        setSearchResult([]);
      }
    } else {
      setSearchResult(null);
    }
  }, [memberList, idx, keyword]);

  const getRoleName = (role) => {
    let _role = "";
    switch (role) {
      case "creator":
        _role = "Creator";
        break;
      case "producer":
        _role = "Producer";
        break;
      case "projectmanager":
        _role = "Project Manager";
        break;
      case "creatormanager":
        _role = "Creator Manager";
        break;
      case "officemanager":
        _role = "Office Manager";
        break;
      default:
        break;
    }
    return _role;
  };

  return (
    <div className="contents">
      <PageTitle title={"Member"} subTitle={"CEKAIメンバー紹介"} />
      <div className="searchBar">
        <svg
          className="searchBar__icon"
          width="21"
          height="22"
          viewBox="0 0 21 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="7.5" stroke="white" />
          <line
            x1="14.3536"
            y1="15.6464"
            x2="20.3536"
            y2="21.6464"
            stroke="white"
          />
        </svg>

        <input
          className="searchBar__input"
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          placeholder="Type a word to search"
        />
      </div>

      {searchResult && searchResult.length > 0 && (
        <div className="member">
          <div className="member-row member-row--search-result">
            <div className="member-list-row member-list-row--search-result">
              {searchResult.map((data) => {
                return <MemberCard key={data.uid} data={data} />;
              })}
            </div>
          </div>
        </div>
      )}
      <div className="member">
        {!searchResult &&
          member &&
          Object.keys(member).map((role, i) => {
            if (Object.keys(member[role]).length === 0) {
              return null;
            }
            return (
              <React.Fragment key={role}>
                <div>
                  <h3 className={["member-role", i === 0 && "first"].join(" ")}>
                    {getRoleName(role)}
                  </h3>
                  {Object.keys(member[role])
                    .sort()
                    .map((col) => {
                      return (
                        <div className="member-row" key={col}>
                          <div className="member-index-row">
                            <p className="index">{col}</p>
                          </div>
                          <div className="member-list-row">
                            {member[role][col].map((data) => (
                              <MemberCard key={data.uid} data={data} />
                            ))}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
}

export default Member;
