import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../AuthContext";
import { useParams, useHistory } from "react-router-dom";
import { db } from "../../Firebase";

/* components */
import Comments from "../Comments";
import { IEdit } from "../common/Icon";

/* styles */
import "./styles.scss";

const getDate = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const h = date.getHours();
  const m = date.getMinutes();

  return `${year}.${month + 1}.${day + 1}  ${h}:${m}`;
};

const PostDetail = ({ postType = "projects" }) => {
  const history = useHistory();
  let { pid } = useParams();
  const projectsCollection = db.collection("posts");
  const { currentUser } = useContext(AuthContext);
  const [project, setProject] = useState(null);
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleEdit = () => {
    history.push(`/${postType}/${pid}/edit`);
  };

  useEffect(() => {
    let unmounted = false;

    const f = async () => {
      setIsLoading(true);
      const projectRef = await projectsCollection.doc(pid);
      projectRef.get().then((doc) => {
        if (!unmounted) {
          if (doc.exists) {
            const data = doc.data();
            setProject(data);
            setDate(data.created_at.toDate());
            setIsLoading(false);
          }
        }
      });
    };
    f();

    const cleanup = () => {
      unmounted = true;
    };
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pid]);
  if (project === null) {
    return null;
  }

  return (
    <div className="project-detail">
      <div className="project-detail__title">
        <h1>
          {isLoading ? "" : project.title}
          <p className="post-type">#{postType}</p>
        </h1>

        {(project.author.uid === currentUser.uid ||
          currentUser.role === "admin") && (
          <button onClick={handleEdit}>
            <IEdit size="m" color="black" />
            <span>Edit</span>
          </button>
        )}
      </div>

      <div className="project-detail__meta">
        <div className="project-detail__author">
          {!isLoading && (
            <React.Fragment>
              <img
                src={
                  project.author
                    ? project.author.avatar || "/avatar.svg"
                    : "/avatar.svg"
                }
                alt=""
                className="project-detail__avatar"
              />
              <p className="project-detail__author-name">
                {project.author && project.author.displayName
                  ? project.author.displayName
                  : "anonymous"}
              </p>
            </React.Fragment>
          )}
        </div>

        {!isLoading && (
          <p className="project-detail__date">{date && getDate(date)}</p>
        )}
      </div>
      {!isLoading && project.mainvisual && (
        <div className="project-detail__mainvisual">
          <img src={project.mainvisual} alt="" />
        </div>
      )}

      {!isLoading && (
        <React.Fragment>
          <div
            className="project-detail__contents"
            dangerouslySetInnerHTML={{ __html: project.contents }}
          ></div>
          <Comments pid={pid} postType={postType} />
        </React.Fragment>
      )}
    </div>
  );
};

export default PostDetail;
