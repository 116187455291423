import React from "react";
import { useParams } from "react-router-dom";

import { db } from "../../../Firebase";

import "./styles.scss";

export const QandADetail = () => {
  let { slug } = useParams();
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    const unsubscribe = db
      .collection("ura-cms")
      .doc("qanda")
      .collection("list")
      .doc(slug)
      .onSnapshot((res) => {
        setData({ ...res.data() });
      });
    return unsubscribe;
  }, [slug]);

  return (
    <div>
      {data && (
        <>
          <h3 className="qanda__page-title">{data.title}</h3>
          <div
            className="qanda__contents"
            dangerouslySetInnerHTML={{ __html: data.contents }}
          ></div>
        </>
      )}
    </div>
  );
};
