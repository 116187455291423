import React, { useState, useCallback, useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { useParams } from "react-router-dom";
import classNames from "classnames";

import firebase from "firebase/app";
import "firebase/storage";
import { db, storage } from "../../Firebase";
import { useMediaQuery } from "react-responsive";

import { ContentState, EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { AuthContext } from "../../AuthContext";

import { useDropzone } from "react-dropzone";

/* styles */
import "./styles.scss";
import "./editor.scss";
const acceptFile = "image/*";
const maxFileSize = 1048576;

const EditPost = ({ postType = "projects", history }) => {
  let { pid } = useParams();
  const postRef = db.collection("posts");
  const { currentUser } = useContext(AuthContext);

  const [rawData, setRawData] = useState(null);
  const [_postType, setPostType] = useState(postType);
  const [title, setTitle] = useState("");
  const [files, setFiles] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const onDrop = useCallback((acceptedFiles) => {
    Object.assign(acceptedFiles[0], {
      preview: URL.createObjectURL(acceptedFiles[0]),
    });
    setFiles(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptFile,
    minSize: 0,
    maxSize: maxFileSize,
  });

  const onUpload = async ({ pid, file }) => {
    function uploadImageAsPromise(file) {
      const file_name = file.name;
      const storageRef = storage.ref(`posts/${pid}/`).child(`${file_name}`);
      return new Promise(function (resolve, reject) {
        //Upload file
        const task = storageRef.put(file);

        //Update progress bar
        task.on(
          "state_changed",
          (snapshot) => {
            console.log("snapshot", snapshot);
          },
          (error) => {
            console.log("err", error);
          },
          () => {
            // 成功時
            console.log("upload complete.");
            task.snapshot.ref.getDownloadURL().then(function (downloadURL) {
              console.log("File available at", downloadURL);
              resolve(downloadURL);
            });
          }
        );
      })
        .then(function (downloadURL) {
          return downloadURL;
        })
        .catch(function () {
          console.log("Error:uploadImageAsPromise");
        });
    }
    const result = await uploadImageAsPromise(file);
    return result;
  };

  const onEditorStateChange = (editorState) => setEditorState(editorState);

  const save = useCallback(async () => {
    try {
      // 元々登録されていた画像と違ったらアップロードし直す
      const mainvisual = files
        ? rawData.mainvisual !== files.preview
          ? await onUpload({ pid, file: files })
          : rawData.mainvisual
        : "";

      await postRef.doc(pid).set(
        {
          pid,
          postType: _postType,
          title,
          mainvisual,
          contents: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
        },
        { merge: true }
      );
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, pid, rawData, files, postRef, title, editorState]);

  const uploadEditorImage = async (file) => {
    const link = await onUpload({ pid, file });
    return { data: { link: link } };
  };

  const remove = useCallback(async () => {
    try {
      await postRef.doc(pid).delete();
      return true;
    } catch (e) {
      alert(e);
    }
  }, [postRef, pid]);

  const handleClick = async () => {
    await save();
    history.push(`/${_postType}/${pid}`);
  };

  const onRemoveClick = async () => {
    await remove();
    history.push(`/${postType}`);
  };

  useEffect(() => {
    let unmounted = false;
    document.querySelector(".contents").scrollTop = 0;
    window.scrollTo(0, 0);

    const f = async () => {
      const doc = await postRef.doc(pid).get();
      if (doc.exists) {
        const data = doc.data();
        if (!unmounted) {
          if (
            data.author.uid !== currentUser.uid &&
            currentUser.role !== "admin"
          ) {
            alert("投稿を編集する権限がありません");
            history.push(`/${postType}`);
          }

          setTitle(data.title);
          setFiles(
            data.mainvisual === "" ? null : { preview: data.mainvisual }
          );
          if (data.contents) {
            const contentBlock = htmlToDraft(data.contents);
            if (contentBlock) {
              const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
              );
              const editorState = EditorState.createWithContent(contentState);
              setEditorState(editorState);
            }
          }

          setRawData(data);
        }
      } else {
        alert("投稿が存在しません");
        history.push(`/${postType}`);
      }
    };
    f();

    const cleanup = () => {
      unmounted = true;
    };
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="post-project">
      <span className="post-project__label">Catgeory</span>
      <div className="category-select__wrap">
        <span className="category-select__hash">#</span>
        <select
          className={classNames("category-select", {
            "is-placholder": !postType,
          })}
          value={postType}
          name="category"
          onChange={(event) => {
            event.persist();
            setPostType(event.target.value);
          }}
        >
          <option value="" disabled selected>
            Select your catgory
          </option>
          <option value="projects">Project</option>
          <option value="wanted">Wanted</option>
          <option value="recommends">Recommend</option>
          <option value="information">Information</option>
        </select>
      </div>

      <span className="post-project__label">Title</span>
      <input
        className="post-project__input"
        type="text"
        value={title}
        placeholder="Fill your title."
        onChange={(e) => setTitle(e.target.value)}
      />

      <span className="post-project__label">Mainvisual</span>
      <div
        className={["dropzone", files && "is-preview"].join(" ")}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : files ? (
          <div className="post-project__visual-wrap">
            <div
              className="post-project__delete-button"
              onClick={(e) => {
                e.stopPropagation();
                setFiles(null);
              }}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 22 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 7L6 20H15.5L19 7" stroke="white" />
                <path d="M11 9V17" stroke="white" />
                <path d="M15 9L14 17" stroke="white" />
                <path d="M7 9.0376L8.10588 17.0375" stroke="white" />
                <path d="M0 5H22" stroke="white" />
                <path d="M8 5L9.23529 1H11.2941H13.7647L15 5" stroke="white" />
              </svg>
            </div>
            <img src={files.preview} alt="" />
          </div>
        ) : (
          <div className="dropzone-attention">
            <svg
              className="dropzone-attention__icon"
              width="73"
              height="73"
              viewBox="0 0 73 73"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="36.5" cy="36.5" r="36" fill="black" stroke="white" />
              <line x1="57" y1="37.5" x2="13" y2="37.5" stroke="white" />
              <line x1="36.5" y1="58" x2="36.5" y2="15" stroke="white" />
            </svg>

            <p className="dropzone-attention__desc">
              {isMobile ? "Add mainvisual" : "Drag and Drop or browse"}
            </p>
          </div>
        )}
      </div>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="editor__wrap"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: [
            "inline",
            "fontSize",
            "link",
            "emoji",
            "image",
            "list",
            "textAlign",
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { popupClassName: "tool-bar__popup", inDropdown: true },
          emoji: { popupClassName: "tool-bar__popup" },
          image: {
            popupClassName: "tool-bar__popup",
            uploadCallback: uploadEditorImage,
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: "auto",
              width: "100%",
            },
          },
          history: { inDropdown: true },
        }}
      />

      <div className="post-project__submit-container">
        <button className="post-project__submit" onClick={handleClick}>
          Update
        </button>
        <button
          className="post-project__submit post-project__submit--delete"
          onClick={onRemoveClick}
        >
          Delete
        </button>
      </div>
    </div>
  );
};
export default withRouter(EditPost);
