import React from "react";
import { Link } from "react-router-dom";
import { Add } from "../../components/common/Icon";

import "./styles.scss";

const HeadPostButton = ({ postType }) => {
  return (
    <Link
      to={{ pathname: "/post", state: { postType } }}
      className="head-post-button"
    >
      <span className="icon">
        <Add />
      </span>
      <span className="label">POST</span>
    </Link>
  );
};

export { HeadPostButton };
