import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useDropzone } from "react-dropzone";

import { AuthContext } from "../../AuthContext";
import firebase, { db, storage } from "../../Firebase";

/* components */
import { Add, Delete, Photo } from "../../components/common/Icon";
import PageTitle from "../../components/common/PageTitle";

import "./styles.scss";

const acceptFile = "image/*";
const maxFileSize = 10048576;

const onUpload = async ({ uid, file }) => {
  function uploadImageAsPromise(file) {
    const file_name = file.name;
    const storageRef = storage.ref(`members/${uid}/`).child(`${file_name}`);
    return new Promise(function (resolve, reject) {
      //Upload file
      const task = storageRef.put(file);

      //Update progress bar
      task.on(
        "state_changed",
        (snapshot) => {
          console.log("snapshot", snapshot);
        },
        (error) => {
          console.log("err", error);
        },
        () => {
          // 成功時
          task.snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    })
      .then(function (downloadURL) {
        return downloadURL;
      })
      .catch(function (e) {
        console.log("Error:uploadImageAsPromise");
        return e;
      });
  }
  const result = await uploadImageAsPromise(file);
  return result;
};

export const EditProfile = () => {
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);

  const [avatar, setAvatar] = React.useState(currentUser.avatar);

  const [role, setRole] = React.useState(currentUser.occupation);
  const [newRole, setNewRole] = React.useState("");

  const [social, setSocial] = React.useState(currentUser.socialList);
  const [newSocialLink, setNewSocialLink] = React.useState({
    type: "facebook",
    link: "",
  });

  const onDrop = React.useCallback((acceptedFiles) => {
    Object.assign(acceptedFiles[0], {
      preview: URL.createObjectURL(acceptedFiles[0]),
    });
    setAvatar(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptFile,
    minSize: 0,
    maxSize: maxFileSize,
  });

  const initializeSocialList = async (ref, data) => {
    const socialList = [];

    if (data.instagram !== "") {
      socialList.push({ type: "instagram", link: data.instagram });
    }
    if (data.twitter !== "") {
      socialList.push({ type: "twitter", link: data.twitter });
    }
    if (data.facebook !== "") {
      socialList.push({ type: "facebook", link: data.facebook });
    }
    if (data.web !== "") {
      socialList.push({ type: "web", link: data.web });
    }
    if (data.contactEmail !== "") {
      socialList.push({ type: "mail", link: data.contactEmail });
    }

    if (socialList.length > 0) {
      await ref.update({
        socialList,
        instagram: firebase.firestore.FieldValue.delete(),
        twitter: firebase.firestore.FieldValue.delete(),
        facebook: firebase.firestore.FieldValue.delete(),
        web: firebase.firestore.FieldValue.delete(),
        contactEmail: firebase.firestore.FieldValue.delete(),
      });
    }
  };

  const onSubmit = async (data) => {
    const { uid } = currentUser;
    const ref = db.collection("members").doc(uid);

    const avatarUrl =
      avatar &&
      (avatar !== currentUser.avatar
        ? await onUpload({ uid: uid, file: avatar })
        : avatar);
    await ref.update({
      avatar: avatarUrl || "",
      occupation: role,
      socialList: social,
    });
    history.push(`/profile`);
  };

  React.useEffect(() => {
    const unsubscribe = async () => {
      const { uid, socialList } = currentUser;
      if (!socialList) {
        const ref = db.collection("members").doc(uid);
        await initializeSocialList(ref, currentUser);
      }
    };
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="contents">
      <PageTitle
        title={"Profile"}
        rightButton={
          <div className="edit-button" onClick={onSubmit}>
            <span>Update</span>
          </div>
        }
      />
      <div className="profile__contents-area">
        <div className="profile__head profile__head--edit">
          <div className="avatar avatar--edit" {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>ここにドロップしてください</p>
            ) : avatar ? (
              <React.Fragment>
                <Photo />
                <img src={avatar.preview || avatar || "/avatar.svg"} alt="" />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Photo />
                <img src={"/avatar.svg"} alt="" />
              </React.Fragment>
            )}
          </div>
          <div className="name-area name-area--edit">
            <h3 className="name">{currentUser.displayName}</h3>
            <p className="name-ja">
              {currentUser.familyNameJa} {currentUser.firstNameJa}
            </p>
            <p className="name-ja">
              {currentUser.familyName} {currentUser.firstName}
            </p>
          </div>
        </div>

        <div className="profile__body">
          <div className="profile__block">
            <h4 className="title">Role</h4>
            <ul className="occupation-list">
              {role &&
                role.map((rl, i) => {
                  return (
                    <div key={i} className="occupation-input">
                      <input
                        type="text"
                        value={rl}
                        onChange={(event) => {
                          event.persist();
                          setRole(() => {
                            return role.map((item, index) => {
                              return i === index ? event.target.value : item;
                            });
                          });
                        }}
                      />
                      <button
                        onClick={() => {
                          const newRoleList = role.filter((item, index) => {
                            return index !== i;
                          });
                          setRole(newRoleList);
                        }}
                        className="delete"
                      >
                        <Delete />
                      </button>
                    </div>
                  );
                })}
              {role && role.length < 4 && (
                <div className="occupation-input occupation-input--add">
                  <input
                    type="text"
                    value={newRole}
                    onChange={(event) => {
                      event.persist();
                      setNewRole(event.target.value);
                    }}
                  />
                  <button
                    className="add"
                    onClick={() => {
                      const newRoleList = [...role, newRole];
                      setRole(newRoleList);
                      setNewRole("");
                    }}
                  >
                    <Add />
                  </button>
                </div>
              )}
            </ul>
          </div>
          <div className="profile__block">
            <h4 className="title">SNS</h4>
            <div className="sns-list">
              {social &&
                social.map((sc, i) => {
                  return (
                    <div key={i} className="sns-input">
                      <div className="sns-input__left-col">
                        <select
                          value={sc.type}
                          name="label"
                          onChange={(event) => {
                            event.persist();
                            setSocial(() => {
                              return social.map((item, index) => {
                                return i === index
                                  ? {
                                      type: event.target.value,
                                      link: item.link,
                                    }
                                  : item;
                              });
                            });
                          }}
                        >
                          <option value="facebook">facebook</option>
                          <option value="twitter">twitter</option>
                          <option value="instagram">instagram</option>
                          <option value="web">web</option>
                          <option value="mail">mail</option>
                        </select>
                        <input
                          type="text"
                          value={sc.link}
                          onChange={(event) => {
                            event.persist();
                            setSocial(() => {
                              return social.map((item, index) => {
                                return i === index
                                  ? {
                                      type: item.type,
                                      link: event.target.value,
                                    }
                                  : item;
                              });
                            });
                          }}
                        />
                      </div>
                      <button
                        onClick={() => {
                          const newSocialList = social.filter((item, index) => {
                            return index !== i;
                          });
                          setSocial(newSocialList);
                        }}
                        className="delete"
                      >
                        <Delete />
                      </button>
                    </div>
                  );
                })}
              {social && social.length < 5 && (
                <div className="sns-input sns-input--add">
                  <div className="sns-input__left-col">
                    <select
                      value={newSocialLink.type}
                      onChange={(event) => {
                        event.persist();
                        setNewSocialLink({
                          type: event.target.value,
                          link: newSocialLink.link,
                        });
                      }}
                      name="label"
                    >
                      <option value="facebook">facebook</option>
                      <option value="twitter">twitter</option>
                      <option value="instagram">instagram</option>
                      <option value="web">web</option>
                      <option value="mail">mail</option>
                    </select>
                    <input
                      type="text"
                      value={newSocialLink.link}
                      onChange={(event) => {
                        event.persist();
                        setNewSocialLink({
                          type: newSocialLink.type,
                          link: event.target.value,
                        });
                      }}
                    />
                  </div>
                  <button
                    className="add"
                    onClick={() => {
                      const newSocialList = [...social, newSocialLink];
                      setSocial(newSocialList);
                      setNewSocialLink({
                        type: "facebook",
                        link: "",
                      });
                    }}
                  >
                    <Add />
                  </button>
                </div>
              )}
            </div>
          </div>
          <button className="submit" onClick={onSubmit}>
            <span className="submit__label">Update</span>
          </button>
        </div>
      </div>
    </div>
  );
};
